$color-primary: #259ac5;
$color-required: #e53e3e;
$disabled-color: #c6c5c4;

// NOTE: study에 전반적으로 적용되어야 하는 속성
$app-study-asterisk-color: #ff4916;

// NOTE: entry screen, page에 전반적으로 적용되어야 하는 속성
$app-page-border-color: #ddd;
$app-page-border-color-selected: #aaa;
$app-page-background-color: #faf7f5;
$app-page-background-color-white: #ffffff;
$app-page-background-color-selected: #faf9f8;
$app-page-theme-light-dark: #404040;
$app-page-theme-light-dark-background-color-selected: #1b1b1b;

// NOTE: domain에 적용되어야 하는 속성
$app-domain-background-color-hover: #f4f4f4;

// NOTE: matrix에 적용되어야 하는 속성
$app-schedule-matrix-border-color: #e1e5e7;
$app-schedule-matrix-element-hover-color: #faf9f8;

:root {
  --color-primary: #259ac5;
  --color-required: #e53e3e;
  --color-disabled: #c6c5c4;

  // NOTE: study에 전반적으로 적용되어야 하는 속성
  --app-study-asterisk-color: #ff4916;

  // NOTE: entry screen, page에 전반적으로 적용되어야 하는 속성
  --app-page-border-color: #ddd;
  --app-page-border-color-selected: #aaa;
  --app-page-background-color: #faf7f5;
  --app-page-background-color-white: #ffffff;
  --app-page-background-color-selected: #faf9f8;
  --app-page-theme-light-dark: #404040;
  --app-page-theme-light-dark-background-color-selected: #1b1b1b;
}
